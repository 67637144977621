import React from "react"
import PropTypes from "prop-types"

import "./footer.sass"
import Link from "gatsby-link"
import SocialMediaIcons from "../social-media-icons/social-media-icons"

const Footer = () => (
  <footer className={"_q"}>
    <div className="_aq _13">
      <div className="_14">
      <div className="_r">
        <SocialMediaIcons></SocialMediaIcons>
        <div className="_s">
          <p>Die Fahrschule Rauscher in Backnang ist deine Fahrschule für Autoführerscheine der Klassen B, BE, B196 und B197, Motorradführerscheine der Klassen A, A1, A2 AM, und Aufbauseminare für Fahranfänger (ASF-Seminar) für Aspach, Oppenweiler, Sulzbach an der Murr, Weissach im Tal, Waldrems, Auenwald und natürlich Backnang. Bei der Fahrschule Rauscher lernst du nicht einfach nur fahren, sondern das Pflichtbewusste umgehen mit deinem Fahrzeug und anderen Verkehrsteilnehmer, sodass du dich im Straßenverkehr wie zu Hause fühlst.</p>
        </div>
        <ul>
          <li><h6>Auto-Führerschein</h6></li>
          <li><a className={'_4n _4r'} href="/auto/">Allgemeine Informationen</a></li>
          <li><a className={'_4n _4r'} href="/auto/klasse-b/">Klasse B</a></li>
          <li><a className={'_4n _4r'} href="/auto/klasse-be/">Klasse BE</a></li>
          <li><a className={'_4n _4r'} href="/auto/klasse-b196/">Klasse 196</a></li>
          <li><a className={'_4n _4r'} href="/auto/klasse-b197/">Klasse 197</a></li>
        </ul>
        <ul>
          <li><h6>Motorrad-Führerschein</h6></li>
          <li><a className={'_4n _4r'} href="/motorrad/">Allgemeine Informationen</a></li>
          <li><a className={'_4n _4r'} href="/motorrad/klasse-a/">Klasse A</a></li>
          <li><a className={'_4n _4r'} href="/motorrad/klasse-a1/">Klasse A1</a></li>
          <li><a className={'_4n _4r'} href="/motorrad/klasse-a2/">Klasse A2</a></li>
          <li><a className={'_4n _4r'} href="/motorrad/klasse-am/">Klasse AM</a></li>
        </ul>
        <ul>
          <li><h6>ASF-Seminar</h6></li>
          <li><a className={'_4n _4r'} href="/asf-seminar/">Allgemeine Informationen</a></li>
        </ul>
        <ul>
          <li><h6>Fahrschule Rauscher</h6></li>
          <li><a className={'_4n _4r'} href="/anmeldung/">Anmeldung</a></li>
          <li><a className={'_4n _4r'} href="/kontakt/">Kontakt</a></li>
          <li><a className={'_4n _4r'} href="/kontakt/">Anfahrt & Öffnungszeiten</a></li>
        </ul>
        <ul>
          <li><h6>Service</h6></li>
          <li><a className={'_4n _4r'} href="/fahrschul-wechsel/">Fahrschul-Wechsel</a></li>
        </ul>
        <ul>
          <li><h6>Rechtliches</h6></li>
          <li><a className={'_4n _4r'} href="/impressum/">Impressum</a></li>
          <li><a className={'_4n _4r'} href="/datenschutz/">Datenschutz</a></li>
          <li><a className={'_4n _4r'} href="/agb/">Allgemeine Geschäftsbedingungen</a></li>
        </ul>
          <a className={"_4n _4p"} href="https://foolsparadise.de" target={"_blank"}>Website erstellt
            von fool's paradise Werbeagentur</a>
      </div>
    </div>
    </div>
  </footer>
)

export default Footer
